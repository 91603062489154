// CSS Imports
import '/src/css/style.pcss';

// NPM Imports
import 'lazysizes';
import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';
import persist from '@alpinejs/persist';

window.Alpine = Alpine;
Alpine.plugin(collapse);
Alpine.plugin(persist);
Alpine.start();


// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

const swiper = new Swiper(".swiper", {
  slidesPerView: 1,
  loop: true,
  
  // pagination: {
  //   el: ".carousel-pagination",
  //   clickable: true,
  // },
  
  navigation: {
    nextEl: '.carousel-navigation-next',
    prevEl: '.carousel-navigation-prev',
    hiddenClass: 'hidden'
  },
});


//COMPONENTS IMPORTS
// import swiperProjects from './components/swiper-projects.example';
// import gallery from './components/gallery.example';
//
// //INIT COMPONENTS
// swiperProjects();
// gallery();
